<template>
  <div class="wrapper">
    <div class="password-reset inner-wrapper">
      <div class="accounts-form">
        <h1
          class="accounts-form__small-bi-header cursor"
          @click="$router.push('/introduction/main')"
        >
          Qpick
        </h1>
        <h1 class="accounts-form__header">비밀번호 찾기</h1>
        <div class="accounts-form__description">
          가입 시 등록한 이메일(ID)을 인증하여<br />
          새로운 비밀번호로 변경할 수 있습니다.
        </div>
        <outlinedTextField
          placeholder="아이디(이메일)"
          @value="setEmail"
          class="accounts-form__outlined-text"
          type="text"
        ></outlinedTextField>
        <p class="accounts-form__form-error" v-if="error.email">
          {{ error.email }}
        </p>
        <button class="accounts-form__submit" @click="send">
          이메일 인증하기
        </button>
      </div>
    </div>
    <toastMsg ref="toast"></toastMsg>
  </div>
</template>
<script>
import userAPI from "@/api/accounts/user.js";
import "@/assets/scss/accounts/basis.scss";
import {createNamespacedHelpers} from "vuex";
const ModuleAlert = createNamespacedHelpers("ModuleAlert");

export default {
  data() {
    return {
      email: "",
      count: 0,
      error: {email: ""}
    };
  },
  computed: {},
  created() {
    this.$EventBus.$on("alertCancel", event => {
      switch (event) {
        case "close":
          this.setAlertVisible(false);
          break;
      }
    });
  },
  methods: {
    setEmail(v) {
      this.email = v;
    },
    send() {
      this.error.email = "";

      try {
        if (
          !this.email.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/)
        ) {
          throw {key: "email", value: "이메일 형식이 맞지 않습니다."};
        }
        if (this.count < 6) {
          userAPI.resetPasswordSend(this.email).then(res => {
            switch (res.status) {
              case 204:
                this.count++;
                this.$refs.toast.show(
                  "인증 요청 메일이 발송되었습니다.\n" +
                    "메일 본문 “이메일 인증”버튼을 클릭하여\n" +
                    "인증해주세요."
                );
                break;

              case 404:
                this.$refs.toast.show("가입되어있는 아이디가 아닙니다.");
                break;

              default:
                this.$refs.toast.show(res.data.errors[0].userMsg);
                break;
            }
          });
        } else {
          this.setAlert({
            isVisible: true,
            message: "이메일 인증하기는 5회 까지 발송할 수 있습니다.",
            event: "close"
          });
        }
      } catch (e) {
        console.log(e);
        this.error[e.key] = e.value;
      }
    },
    ...ModuleAlert.mapMutations(["setAlert", "setAlertVisible"])
  },
  components: {
    outlinedTextField: () =>
      import("@/components/accounts/outlined-text-field.vue"),
    toastMsg: () => import("@/components/accounts/toast-message.vue")
  }
};
</script>
<style lang="scss"></style>
