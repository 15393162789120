var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "wrapper"
  }, [_c('div', {
    staticClass: "password-reset inner-wrapper"
  }, [_c('div', {
    staticClass: "accounts-form"
  }, [_c('h1', {
    staticClass: "accounts-form__small-bi-header cursor",
    on: {
      "click": function click($event) {
        return _vm.$router.push('/introduction/main');
      }
    }
  }, [_vm._v(" Qpick ")]), _c('h1', {
    staticClass: "accounts-form__header"
  }, [_vm._v("비밀번호 찾기")]), _vm._m(0), _c('outlinedTextField', {
    staticClass: "accounts-form__outlined-text",
    attrs: {
      "placeholder": "아이디(이메일)",
      "type": "text"
    },
    on: {
      "value": _vm.setEmail
    }
  }), _vm.error.email ? _c('p', {
    staticClass: "accounts-form__form-error"
  }, [_vm._v(" " + _vm._s(_vm.error.email) + " ")]) : _vm._e(), _c('button', {
    staticClass: "accounts-form__submit",
    on: {
      "click": _vm.send
    }
  }, [_vm._v(" 이메일 인증하기 ")])], 1)]), _c('toastMsg', {
    ref: "toast"
  })], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "accounts-form__description"
  }, [_vm._v(" 가입 시 등록한 이메일(ID)을 인증하여"), _c('br'), _vm._v(" 새로운 비밀번호로 변경할 수 있습니다. ")]);
}]

export { render, staticRenderFns }